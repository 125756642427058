// External
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Image from 'next/image';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';

// Types
import type {
  Header,
  Image as ImageType,
  Subheader,
} from '~server/types/content';

const Project = styled('div')(
  ({
    theme: {
      breakpoints,
      spacing,
      meadow: {
        purpose: { background },
      },
    },
  }) => ({
    background: background.extraEmphasis,
    borderRadius: spacing(2),
    boxShadow:
      '0px 0px 4px 0px rgba(7, 7, 7, 0.10), 0px 0px 8px 0px rgba(4, 46, 41, 0.14)',
    cursor: 'pointer',
    display: 'flex',
    height: '88px',
    overflow: 'hidden',
    width: '100%',

    [breakpoints.up('sm')]: {
      flexDirection: 'column',
      height: '100%',
      width: '258px',
    },
  })
);

const ProjectImageContainer = styled('div')(({ theme: { breakpoints } }) => ({
  minWidth: '111px',
  position: 'relative',
  width: '111px',

  [breakpoints.up('sm')]: {
    flex: '163px 0 0',
    width: '100%',
  },
}));

const ProjectData = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),
  justifyContent: 'center',
  overflow: 'hidden',
  padding: spacing(2),

  [breakpoints.up('sm')]: {
    textAlign: 'center',
  },
}));

const ProjectHeader = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [breakpoints.up('sm')]: {
    fontSize: '16px',
  },
}));

const ProjectSubheader = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontSize: '12px',

  [breakpoints.up('sm')]: {
    fontSize: '14px',
  },
}));

export type ServiceCardProps = ImageType & Partial<Header & Subheader>;

// Main Component
const ServiceCard = ({
  header,
  subheader,
  imageText,
  imageUrl,
}: ServiceCardProps) => {
  return (
    <Project>
      <ProjectImageContainer>
        <Image
          alt={imageText}
          fill
          loader={contentfulImageLoader}
          sizes="384px"
          src={imageUrl}
          style={{ objectFit: 'cover' }}
        />
      </ProjectImageContainer>

      <ProjectData>
        <ProjectHeader sx={{ color: 'impact.main' }} variant="subtitle4">
          {header}
        </ProjectHeader>

        {subheader && (
          <ProjectSubheader sx={{ color: 'impact.main' }} variant="body3">
            {subheader}
          </ProjectSubheader>
        )}
      </ProjectData>
    </Project>
  );
};

export default ServiceCard;
