// External
import Box from '@taskrabbit/meadow-web/lib/Box';
import ButtonSecondary from '@taskrabbit/meadow-web/lib/ButtonSecondary';
import Image from 'next/image';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

// Internal
import { ReviewItem } from '~components/marketing/ReviewItem';
import { ResponsiveContainer } from './index.styled';
import contentfulImageLoader from '~utils/contentfulImageLoader';
import postalCodeAtom from '~jotai/atoms/postalCode';

// Types
import type {
  Header,
  Image as ImageType,
  Link,
  Review,
} from '~server/types/content';
import type { SxProps } from '@mui/system';
import type { TrackCtaClicked } from './types';

export type ReviewsProps = Header &
  Partial<ImageType> & {
    locale: string;
    reviews?: Review[];
    starRatingSx?: SxProps;
  } & Partial<Link> &
  Partial<TrackCtaClicked>;

// Styled Components
const StyledBgColorBox = styled(Box)(
  ({
    theme: {
      meadow: {
        purpose: { background },
      },
    },
  }) => ({
    backgroundColor: background.emphasis,
  })
);

const StyledHeader = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { impact },
      },
      spacing,
    },
  }) => ({
    color: impact.emphasis,
    marginBottom: spacing(4),
    paddingRight: spacing(3),
    textAlign: 'left',

    [breakpoints.up('md')]: {
      marginBottom: spacing(6),
      textAlign: 'center',
    },
  })
);

const StyledReviewsItemsContainer = styled('ul')(
  ({ theme: { breakpoints, spacing } }) => ({
    columnGap: spacing(6),
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: spacing(3),
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingInlineStart: 0,
    width: '100%',

    [breakpoints.up('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      marginBottom: spacing(7),
      rowGap: spacing(6),
      width: '700px',
    },

    [breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
      width: 'auto',
    },
  })
);

const StyledImageWrapper = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: '0 auto',
    maxWidth: '354px',
    minHeight: spacing(7),
    position: 'relative',
    width: '100%',

    [breakpoints.up('sm')]: {
      maxWidth: '612px',
      minHeight: spacing(11.25),
    },
  })
);

const StyledStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  alignItems: 'start',

  [breakpoints.up('lg')]: {
    alignItems: 'center',
  },
}));

// Main Component
const Reviews = ({
  header,
  imageText,
  imageUrl,
  linkText,
  linkUrl,
  locale,
  reviews,
  starRatingSx,
  trackCtaClicked,
}: ReviewsProps) => {
  const [postalCode] = useAtom(postalCodeAtom);
  const router = useRouter();

  if (!reviews) return;

  const trackCtaClickedAndNavigate = async () => {
    if (trackCtaClicked) {
      trackCtaClicked('reviews', postalCode);
    }

    if (linkUrl) {
      router.push(linkUrl, undefined, { locale });
    }
  };

  return (
    <StyledBgColorBox>
      <ResponsiveContainer data-testid="reviews-container">
        <StyledStack>
          <StyledHeader variant="h2">{header}</StyledHeader>
          <StyledReviewsItemsContainer>
            {reviews.map((review) => (
              <ReviewItem
                key={review.id}
                locale={locale}
                starRatingSx={starRatingSx}
                {...review}
              />
            ))}
          </StyledReviewsItemsContainer>
          {imageUrl && (
            <StyledImageWrapper>
              <Image
                alt={imageText || ''}
                fill={true}
                loader={contentfulImageLoader}
                priority={true}
                sizes="(max-width: 600px) 354px, 612px"
                src={imageUrl}
                style={{ objectFit: 'contain' }}
              />
            </StyledImageWrapper>
          )}
          {linkText && linkUrl && (
            <ButtonSecondary onClick={() => trackCtaClickedAndNavigate()}>
              {linkText}
            </ButtonSecondary>
          )}
        </StyledStack>
      </ResponsiveContainer>
    </StyledBgColorBox>
  );
};

export default Reviews;
