// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import styled, {
  withTypographyRules,
} from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Link from 'next/link';

export const HeroOverlay = styled('div')(({ theme: { meadow } }) => ({
  '&::after': {
    backgroundColor: `${meadow.purpose.background.lightbox}40`,
    bottom: 0,
    content: '""',
    display: 'block',
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export const StyledContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const PaddingContainer = styled(Container)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(6),
  paddingTop: spacing(6),
}));

export const StyledSection = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1440px',
    minWidth: '350px',
    paddingBottom: spacing(6),
    paddingLeft: '4%',
    paddingRight: '4%',
    paddingTop: spacing(6),
    width: '100%',

    [breakpoints.up('lg')]: {
      padding: spacing(11, 19),
    },
  })
);

export const StyledHeader = styled(Typography)(
  ({
    theme: {
      meadow: {
        purpose: { impact },
      },
      spacing,
    },
  }) => ({
    color: impact.emphasis,
    marginBottom: spacing(6),
  })
);

export const StyledLink = styled('a')(
  ({
    theme,
    theme: {
      meadow: {
        purpose: { primary },
      },
    },
  }) =>
    withTypographyRules(theme, 'subtitle3', {
      color: primary.main,
      textDecoration: 'none',
    })
);

export const StyledNextLink = styled(Link)(
  ({
    theme,
    theme: {
      meadow: {
        purpose: { primary },
      },
    },
  }) =>
    withTypographyRules(theme, 'subtitle3', {
      color: primary.main,
      textDecoration: 'none',
    })
);

export const TruncatedText = styled(Typography)(
  ({ $clampValue }: { $clampValue: number }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: $clampValue,
  })
);

export const ResponsiveContainer = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: 'auto',
    maxWidth: '1200px',
    paddingBottom: spacing(6),
    paddingLeft: '4%',
    paddingTop: spacing(6),

    [breakpoints.up('md')]: {
      paddingBottom: spacing(11),
      paddingLeft: spacing(5),
      paddingTop: spacing(11),
    },

    [breakpoints.up('xl')]: {
      maxWidth: '1300px',
    },
  })
);
