// External
import type { ReactNode } from 'react';
import type { Document, Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { TypographyProps } from '@taskrabbit/meadow-web/lib/Typography';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Box from '@taskrabbit/meadow-web/lib/Box';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Types
import type { Options } from '@contentful/rich-text-react-renderer';
import type { BoxProps } from '@taskrabbit/meadow-web/lib/Box';

interface RichTextProps {
  options?: Options;
  richText: Document;
  sx?: BoxProps['sx'];
}

const StyledDocument = styled('div')(({ theme }) => ({
  '& > p, & > hr': {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  a: {
    '&:hover': {
      color: theme.meadow.purpose.primary.main, // Change color to green on hover
    },
  },
}));

const variants: Record<string, TypographyProps['variant']> = {
  [BLOCKS.HEADING_1]: 'h1',
  [BLOCKS.HEADING_2]: 'h2',
  [BLOCKS.HEADING_3]: 'h3',
  [BLOCKS.HEADING_4]: 'h4',
  [BLOCKS.HEADING_5]: 'h5',
  [BLOCKS.HEADING_6]: 'h5',
  [BLOCKS.PARAGRAPH]: 'body1',
};

const RenderDocument = (node: Block | Inline, children: ReactNode) => (
  <StyledDocument>{children}</StyledDocument>
);

const RenderTypography = (node: Block | Inline, children: ReactNode) => (
  <Typography variant={variants[node.nodeType]}>{children}</Typography>
);

const defaultOptions = {
  renderNode: {
    [BLOCKS.DOCUMENT]: RenderDocument,
    [BLOCKS.HEADING_1]: RenderTypography,
    [BLOCKS.HEADING_2]: RenderTypography,
    [BLOCKS.HEADING_3]: RenderTypography,
    [BLOCKS.HEADING_4]: RenderTypography,
    [BLOCKS.HEADING_5]: RenderTypography,
    [BLOCKS.HEADING_6]: RenderTypography,
    [BLOCKS.PARAGRAPH]: RenderTypography,
  },
};

const RichText = ({ options, richText, sx }: RichTextProps) => (
  <Box sx={sx}>
    {documentToReactComponents(richText, { ...defaultOptions, ...options })}
  </Box>
);

export default RichText;
